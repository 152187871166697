.dashboard-hint {
    color: white;
}

.generated-graph-container {
    height: 100%;
    width: 100%;
}

.generated-graph {
    height: 100%;
    width: 100%;
}

/* .graph-container {
    display: flex;
    position: relative;
    height: 100%;
    width: 100%;
} */

.graph {
    width: 100%;
    height: 100%;
    max-width: 100%; /* Ensures graph does not exceed parent width */
    max-height: 100%; /* Prevents graph from exceeding parent height */
    position: absolute;
    top: 0px;
    right: 0px;
    overflow: hidden;
}

.base64-image {
    max-width: 100%;
    max-height: 90%;
    height: auto;
    padding: 5% 5% 5% 5%;
}