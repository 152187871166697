/* Original CSS doesnt seem to work with antd className and dynamically switch theme */
/* .app {
  display: 'flex';
  flex-direction: column;
} */

/* .app-header, .app-footer {
  height: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1%;
  font-weight: bold;
  color:white;
} */

/* .app-header {
  background-color: #030852;
  align-items: left;
  font-size: calc(8px + 2vmin);
  padding-left: 24px;
  color: white;
  justify-content: left;
} */

/* .app-footer {
  align-items: center;
  justify-content: center;
} */

/* Dashboard */
.Dashboard {
  display: flex;
  width: auto;
  height: 100%;
}

.dashboard-filters {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 1% 2% 1%;
  width: max-content;
}

.dashboard-hint, .region-menu, .invtype-menu, .dashboard-graph {
  margin-top: 5%;
  width: auto;
}
